@tailwind base;
@tailwind components;
@tailwind utilities;

html,  
body,
main {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
}

.show-text {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.hide-text {
  opacity: 0;
}

.hideMenuNav {
  display: none;
}

.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 50vh;
  top: 0;
  left: 0;
  background: #0f172a;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}